import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Modal, ModalBody } from 'reactstrap'
import { useResendKpkEventsMutation } from 'src/services/kpk/kpkEvents'
import { ToastContainer, toast } from 'react-toastify'
import { handleErrors } from 'src/utils'

interface EventsResendProps {
  isOpen: boolean
  toggle: () => void
  entity: {
    profileId: string
    applicationId: string
    loanId: string
  }
}

export const EventsResend: FC<EventsResendProps> = ({
  isOpen = false,
  toggle,
  entity
}) => {
  const { t } = useTranslation()
  const [
    resendEvents,
    {
      isLoading: isResendEventsloading,
      error: resendEventsError,
      isSuccess: isResendEventsSuccess
    }
  ] = useResendKpkEventsMutation()

  if (!isOpen) return <Button onClick={toggle}>{t('Resend Events')}</Button>

  return (
    <>
      <ToastContainer />
      <Modal isOpen={isOpen} toggle={toggle} centered>
        <ModalBody>
          <div>{t('Resend Events')}</div>
          <br />
          <Button
            onClick={() => {
              resendEvents({ profileId: entity.profileId })
              if (isResendEventsSuccess) {
                toast.success(t('Operation success'))
              } else {
                toast.error(t(handleErrors(resendEventsError)))
              }
            }}
            disabled={isResendEventsloading}
          >
            {t('Resend Client Data')}
          </Button>
          <br />
          <br />
          <Button
            onClick={() => {
              resendEvents({ loanId: entity.loanId })
              if (isResendEventsSuccess) {
                toast.success(t('Operation success'))
              } else {
                toast.error(t(handleErrors(resendEventsError)))
              }
            }}
            disabled={isResendEventsloading}
          >
            {t('Resend Loan && Application Data')}
          </Button>
          <br />
          <br />
          <Button
            onClick={() => {
              resendEvents({ applicationId: entity.applicationId })
              if (isResendEventsSuccess) {
                toast.success(t('Operation success'))
              } else {
                toast.error(t(handleErrors(resendEventsError)))
              }
            }}
            disabled={isResendEventsloading}
          >
            {t('Resend Application Data')}
          </Button>
          <br />
          <br />
        </ModalBody>
      </Modal>
    </>
  )
}
