import { FC, useState } from 'react'
import { useGetKpkEventsQuery } from 'src/services/kpk/kpkEvents'
import List from 'src/Components/Common/EntitiesView/List'
import Loader from '../../Loader'
import { EventsInfo } from './EventsInfo'
import { EventsResend } from './EventsResend'
interface KpkEventsProps {
  loanId: string | null
  profileId: string | null
  applicationId?: string | null
}

export const KpkEvents: FC<KpkEventsProps> = ({
  loanId,
  profileId,
  applicationId
}) => {
  const [isOpenResend, setIsOpenResend] = useState<boolean>(false)
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [currentEntity, setCurrentEntity] = useState({})

  const fieldsDescription = {
    profile_id: {
      label: 'profile',
      mappingValue: () => <i className='ri-eye-line fs-15' />,
      isLink: true,
      linkFieldName: 'profile_link'
    },
    application_id: {
      label: 'application',
      mappingValue: () => <i className='ri-eye-line fs-15' />,
      isLink: true,
      linkFieldName: 'application_link'
    },
    created_at: {
      label: 'created_at',
      isDateTime: true
    },
    event_type: {
      label: 'event_type_kpk'
    },

    doc_num: {
      label: 'doc_num_kpk'
    },
    result_text: {
      label: 'result_text_kpk'
    }
  }

  const updateEntity = (
    response_code: number,
    result_status: number,
    request_data: any,
    response_data: any
  ) => {
    setCurrentEntity({
      response_code: response_code,
      result_status: result_status,
      request_data: request_data,
      response_data: response_data
    })

    setIsOpen(true)
  }

  const toggleResend = () => {
    setIsOpenResend(!isOpenResend)
  }

  const toggle = () => {
    setIsOpen(false)
    setCurrentEntity({})
  }

  const {
    data: entity,
    isSuccess: success,
    isLoading: loading
  } = useGetKpkEventsQuery(loanId ?? '', { skip: loanId === null })

  if (loading) return <Loader />

  return (
    <>
      <EventsResend
        isOpen={isOpenResend}
        toggle={toggleResend}
        entity={{
          profileId: profileId ?? '',
          applicationId: applicationId ?? '',
          loanId: loanId ?? ''
        }}
      />
      <EventsInfo
        isOpen={isOpen}
        toggle={toggle}
        currentEntity={currentEntity}
      />
      <br />
      <br />
      {success && entity && (
        <List
          style={{ width: 'fit-content' }}
          list={entity.map((item) => ({
            ...item,
            profile_link: `/profile-view?id=${item.profile_id}`,
            application_link: `/application-view?id=${item.application_id}`
          }))}
          control={(item: (typeof entity)[number]) => (
            <td className='d-flex flex-row gap-2'>
              <button
                onClick={() =>
                  updateEntity(
                    item.response_code,
                    item.result_status,
                    item.request_data,
                    item.response_data
                  )
                }
                className='btn text-success p-0'
              >
                <i className='ri-eye-line fs-15' />
              </button>
            </td>
          )}
          fieldsDescription={fieldsDescription}
          className='table-sm align-middle table-nowrap mb-0'
          hover
        />
      )}
    </>
  )
}
